<template>
  <div>
    <div>
      <div class="grid-container">
        <div class="grid-row grid-gap">
          <document-renderer
              :documentId="pageData.fields.leftMenuRef.sys.id" v-if="!hideSideNav"
            ></document-renderer>
            <div class="usa-layout-docs__main desktop:grid-col-9 usa-prose" :class="hideSideNav ? 'desktop:grid-col-12':''">

              <div key="test"></div>
              <div style="margin: 1em 1em 3em;" v-if="pageData.fields.bodyText">
                <rich-text-renderer :data="pageData.fields.bodyText"></rich-text-renderer>
              </div>
              <div class="slip-grid" v-if="pageData.fields.amount">
                  <rich-text-renderer :data="pageData.fields.amount"></rich-text-renderer> {{ usdAmount }}
                </div>
                <div class="slip-grid" v-if="pageData.fields.expiry">
                  <rich-text-renderer :data="pageData.fields.expiry"></rich-text-renderer>
                </div>
                <div class="slip-grid" v-if="pageData.fields.bankName">
                  <rich-text-renderer :data="pageData.fields.bankName"></rich-text-renderer>
                </div>
                <div class="slip-grid" v-if="pageData.fields.IBAN ">
                  <rich-text-renderer :data="pageData.fields.IBAN"></rich-text-renderer>
                </div>
                <div class="slip-grid"  v-if="pageData.fields.cgiRef">
                  <rich-text-renderer :data="pageData.fields.cgiRef"></rich-text-renderer> {{ refNum }}
                </div>
                <div class="slip-grid" v-if="pageData.fields.swift">
                  <rich-text-renderer :data="pageData.fields.swift"></rich-text-renderer>
                </div>
                <div class="slip-grid" v-if="pageData.fields.beneficiary">
                  <rich-text-renderer :data="pageData.fields.beneficiary"></rich-text-renderer>
                </div>
              <div v-html="htmlData"> </div>
                <div
                  class="usa-link cgi-link-next block-link"
                  v-if="pageData.fields.backUrl"
                >
                  <svg width="24" height="24" viewBox="0 0 192 512" role="img" focusable="false" aria-labelledby="svgTitle" aria-describedby="svgDescription">
                    <title id="svgTitle">Back</title>
                    <desc id="svgDescription">Back</desc>
                    <path
                      d="M4.2 247.5L151 99.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L69.3 256l118.5 119.7c4.7 4.7 4.7 12.3 0 17L168 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 264.5c-4.7-4.7-4.7-12.3 0-17z"
                      fill="#0071bc"
                    ></path>
                  </svg>

                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.8.1/jquery.min.js"></script>

<script>
import {
  getCountryResourceGroup,
  getSystemGlobalResources,
  getDataByContentType,
  getExchangeRateExpiry,
  getExchangeRate,
  getBarcodeUrl,
  getCountryName,
  getCurrencyCode,
  getRefDigit,
  getVisaTypes,
  getWeights
} from "@/store/api";
import { urlSlug,urlLangCode } from "@/helpers/url";
import PageNotFound from "@/components/PageNotFound";

export default {
  name: "deposit-slip-template",

  components: { PageNotFound },
  props: ["deposit", "usd_amount"],
  data() {
    return {
      dataLoaded: false,
      pageData: {},
      show404: false,
      tierCode: "",
      tier: "test",
      fee: "",
      quantity: "",
      usd_amount: 100,
      expiryDate: "",
      url: "",
      htmlData: "",
      referenceNo: ""
    };
  },
  computed: {
    feecalc() {
      parameters = this.$route.query;
      console.log("sdadadasdasd" + parameters.rnum);
      referenceNumber = parameters.rnum;
      return this.tier;
    },
  },
  created() {
    const urlSlugText = urlSlug();
    var parameters, referenceNumber, quantity, tierCode, tier, fee, usd_amount;
    getDataByContentType(
      "depositSlip",
      urlSlugText,
      this.$route.params.countrycode,
      this.$route.params.langcode
    ).then((pageData) => {
      this.dataLoaded = true;
      console.log(this.pageData);
      if (!pageData) {
        this.show404 = true;
      }

      // Avnish, 04-Sept-2023, Requirement - check and show the country is live or not.
      getCountryResourceGroup(this.$route.params.countrycode).then((res) => {
        if(res?.fields?.resJson) {
          let globalResourceNotLiveArr = res.fields.resJson['non-live-countries'];
          // globalResourceNotLiveArr = [{"country": "de","language": "en","isNonLive": "true" }];
          console.log("globalResourceNotLiveArr:",globalResourceNotLiveArr);
          console.log("route Country and Lang Code:: ", this.$route.params.countrycode, this.$route.params.langcode)
          if(globalResourceNotLiveArr) {
            let filterCurrentCountryObj = globalResourceNotLiveArr.filter(el => el.country == this.$route.params.countrycode.trim() && ((el.language == this.$route.params.langcode.trim() && (el.isNonLive == 'true' || el.isNonLive == true)) || (!el.language && (el.isNonLive == 'true' || el.isNonLive == true))));
            console.log("filterCurrentCountryObj:", filterCurrentCountryObj);
            if(filterCurrentCountryObj.length > 0) {
              if(filterCurrentCountryObj[0]['isNonLive'] == "true" && this.$route.query.accesstoken && this.$route.query.accesstoken == filterCurrentCountryObj[0]['accesstoken']) {
                console.log("country level token wise accesible for non live country.", this.$route.query.accesstoken);
              }
              else {
                getSystemGlobalResources().then((globalResponse) => {
                  let globalAccessToken = globalResponse?.fields?.resJson?.accesstoken;
                  // globalAccessToken = "20ca9a431341756398632ada6b761820";
                  if(this.$route.query.accesstoken && globalAccessToken == this.$route.query.accesstoken) {
                    console.log("global level token wise accesible for non live country.", this.$route.query.accesstoken);
                  }
                  else {
                    this.show404 = true;
                  }
                });
              }
            }
          }
        }
      });

      this.pageData = pageData;
      this.htmlData = this.pageData.fields.pageBody;
      this.dataReplacement;
      this.setPageTitle(this.pageData.fields.pageTitle || "USTravelDocs");

      parameters = this.$route.query;
      
      referenceNumber = parameters.rnum;
      if(referenceNumber != undefined) {
        tierCode = referenceNumber.substr(0, 2);
      }
      else {
        fee = parameters.fee;
      }
      //   console.log("Tiercode:" + tierCode)
      //    quantity = referenceNumber.substr(2, 4)
      //   console.log("Quantity:" + quantity)

      if (tierCode === "73") {
        tier = 1;
        fee = 160;
        console.log("feeeee:"+fee);
      } else if (tierCode === "85") {
        tier = 2;
        fee = 190;
      } else if (tierCode === "62") {
        tier = 3;
        fee = 265;
      } else if (tierCode === "40") {
        tier = 4;
        fee = 205;
      }
      // console.log("tier:" + tier)
      // console.log("fee" + fee)
      usd_amount = quantity * fee;
    });
  },
  computed: {
    hideSideNav() {
      const urlSlugText = urlSlug();
      if (urlSlugText == "privacy-policy") return "display-none";
      else return "";
    },
    refNum() {
      var parameters,
        referenceNumber,
        quantity,
        tierCode,
        tier,
        fee,
        usd_amount;
      parameters = this.$route.query;
      referenceNumber = parameters.rnum
      var tierCode;  
        var quantity;          
        if(!referenceNumber){
          tierCode = parameters.fee; 
          console.log("tierCode"+tierCode);
          quantity = parameters.quantity;   
          var randomNumber = new Array();
        referenceNumber = ''+tierCode;

        if (quantity < 10) {
            referenceNumber  += '0'+quantity;
          } else {
            referenceNumber  += quantity;
        }
          
        for(var i = 0; i < 10; i++) {   

        randomNumber[i]=Math.floor(Math.random()*10);   

        referenceNumber = referenceNumber + "" + randomNumber[i];

        }
        
      } else {
        tierCode = referenceNumber.substr(0, 2)
        quantity = referenceNumber.substr(2, 4)
      }
      return referenceNumber;
    
    },
      receiptNumberGen(){
        var randomnumber=new Array();	
        var receiptnumber = "";
        for(var i = 0; i < 9; i++) {	
          randomnumber[i]=Math.floor(Math.random()*10);	
          receiptnumber += randomnumber[i];	
          }
        return receiptnumber;
      },
    dataReplacement(){
      const urlSlugText = urlSlug();
      // this.htmlData = ` replace string 
      //       DepositAmtUSD => {DepositAmtUSD} ,  
        //       DepositAmtLocal => {DepositAmtLocal}, 
        //       ExpDate => {ExpDate},
        //       RefNo => {RefNo},
        //       tierCode => {tierCode},
        //       Qty => {Qty},
        //       Fee => {Fee},
        //       Barcode1 => {Barcode1},`;  
        var today = new Date();


        var re = new RegExp("{RandomNumber8}", 'g');
        var refNo;
        refNo = Math.floor(10000000 + Math.random() * 90000000);
        this.htmlData = this.htmlData.replace(re,refNo);
        console.log("----",refNo);

      // this.htmlData = this.htmlData;
      // console.log('page-content',this.htmlData);
      //pooja, 23-nov-22, get all values from function
      getCountryName(this.$route.params.countrycode).then(
      (countryName) => {
        this.countryName = countryName;
        // console.log("this.countryName", this.countryName);
        getCurrencyCode(this.$route.params.countrycode).then(
        (currencyCode) => {
          this.currencyCode = currencyCode;                
          // console.log("currencyCode", this.currencyCode);
          getRefDigit(this.$route.params.countrycode).then(
            (refDigit) =>{ 
              this.refDigit = refDigit;                
            // console.log("refDigit", this.refDigit);
          getExchangeRate(this.$route.params.countrycode).then(
            (ratio) => {
              this.ratio = ratio;
            getExchangeRateExpiry(this.$route.params.countrycode).then(
              (expiryDate) => {
                this.expiryDate = expiryDate;
              getVisaTypes(urlLangCode()).then(
                (visaTypes) => {
                  this.visaTypes = visaTypes;
                  // console.log("expiryDate", this.expiryDate);
                  // console.log("ratio", this.ratio);
                  var mrvRef =
                    Pad(today.getDate(), 2) +
                    Pad(today.getMonth() + 1, 2) +
                    today.getFullYear().toString().substr(2, 4) +
                    Pad(today.getHours(), 2) +
                    Pad(today.getMinutes(), 2) +
                    Pad(today.getSeconds(), 2)+
                    Pad(today.getMilliseconds().toString().substr(0, 1), 2);
                  console.log("tiercode = " + tierCode);
                  console.log("quantity = " + quantity);
                  console.log("mrvRef = " + mrvRef);
                  console.log("date = " + Pad(today.getDate(), 2));
                  console.log("month = " + Pad(today.getMonth() + 1, 2));
                  console.log("year = " + today.getFullYear().toString().substr(2, 4));
                  console.log("hours = " + Pad(today.getHours(), 2));
                  console.log("min = " + Pad(today.getMinutes(), 2));
                  console.log("milliseconds = " + today.getMilliseconds().toString().substr(0, 1), 2);

                  var year = this.expiryDate.substr(0, 4);
                  // console.log("year:" + year);
                  var mth = this.expiryDate.substr(5, 2);
                  // console.log("mth:", mth);
                  var dt = this.expiryDate.substr(8, 10);
                  // console.log("dt:", dt);
                  var dob1 = dt + mth + year;
                  console.log("dob1", dob1);
                  var expiration_date2 = dt + mth +year;
                  // console.log("expiration_date2", expiration_date2);
                  var parameters = this.$route.query;
                  var fee = parameters.fee;
                  console.log("parameters = " + parameters);
                  var amount = parameters.amount;
                  var visaClass = parameters.visaClass;
                  var referenceNumber = parameters.rnum;
                  // Avnish, 07-June-2023, Korea changes.
                  var UniqueBeneficiaryAccNo;
                  if (!referenceNumber)
                  {
                      var quantity = parameters.quantity;
                      var tierCode = parameters.fee;
                  }
                  else
                  {
                      var tierCode = referenceNumber.substr(0, 2);
                      var quantity = referenceNumber.substr(2, 4);
                  }
                  var uid = parameters.uid;

                  //pooja, 03-mar-23, for fee and tierCode now we are taking from lang global resource group by code
                  var codeParameter = parameters.code;
                  
                  var visaDetails;
                  var tier;
                  if(codeParameter){
                    visaDetails =  this.visaTypes.filter(value => {
                      return (value['code-value'] == codeParameter)? value:null;
                    })};
                  if(tierCode){
                      visaDetails =  this.visaTypes.filter(value => {
                        return (value['tier-code'] == tierCode)? value:null;
                      })};
                  if(visaClass){
                    visaDetails =  this.visaTypes.filter(value => {
                      return (value['type-code'] == visaClass)? value:null;
                    });
                  }
                  if(amount){
                        visaDetails =  this.visaTypes.filter(value => {
                          return (value['fee'] == amount)? value:null;
                        })};
                  // Avnish, 07-June-2023, Korea changes.
                  if(this.countryName == 'Korea'){
                    console.log("Korea countryName, visaDetails, fee:::",this.countryName, visaDetails, fee);
                    if(visaDetails == null || visaDetails == undefined || visaDetails.length == 0) {
                      if(fee) {
                        visaDetails =  this.visaTypes.filter(value => {
                          return (value['krtier-code'] == fee)? value:null;
                        });
                        if(!amount) {
                          amount = visaDetails[0]['fee'];
                        }
                      }
                    }
                    else {
                      if(!amount) {
                        amount = visaDetails[0]['fee'];
                      }
                    }
                  }
                  console.log('this.visaTypesvisaFee',visaDetails[0]);
                  fee = visaDetails[0]['fee'];
                  tierCode = visaDetails[0]['tier-code'];
                  tier = visaDetails[0]['tier'];
                  console.log("fee : " + fee)
                  console.log("tier-code : " + tierCode)
                  tier = tier.at(-1);
                  console.log("tier : " + tier)

                  if(this.countryName == 'Korea'){
                    if(fee == 185){
                      UniqueBeneficiaryAccNo = '2400';     
                    }
                    if(fee == 205){
                      UniqueBeneficiaryAccNo = '2500';        
                    }
                    if(fee == 265){
                      UniqueBeneficiaryAccNo = '4500';        
                    }
                    if(fee == 315){
                      UniqueBeneficiaryAccNo = '4900';        
                    }
                    var randomnumber=new Array();
                    for(var i = 0; i < 10; i++) {
                      randomnumber[i]=Math.floor(Math.random()*10);
                      UniqueBeneficiaryAccNo = UniqueBeneficiaryAccNo +""+ randomnumber[i];
                    }
                  }

                  //pooja 13-sep-2022 handle if fee and quantity not passed in url
                  if (!referenceNumber) {
                    var randomnumber = new Array();
                    referenceNumber = '';
                    // Avnish, 30-May-2023, Malaysia have reference numbers added 001 in quantity.
                    if(this.refDigit == 10){
                      referenceNumber = "" + tierCode;
                      if (parseInt(quantity) < this.refDigit) {
                        referenceNumber += "0" + parseInt(quantity);
                      } else {
                        if(quantity) {
                          referenceNumber += quantity;
                        }
                      }
                    }
                    for (var i = 0; i < this.refDigit; i++) {
                      randomnumber[i] = Math.floor(Math.random() * 10);
                      referenceNumber = referenceNumber + "" + randomnumber[i];
                    }
                  } else {
                    tierCode = referenceNumber.substr(0, 2);
                    quantity = referenceNumber.substr(2, 2);
                  }
                  var usdAmount = quantity * fee;
                  this.usdAmount = usdAmount;
                  if (isNaN(this.ratio)) {
                    this.local_amount = 'Quantity = ' + quantity + ' fee = ' + fee + ' USD Amt:'  + usdAmount + '<span style="color: red;">Local amount not found exchange rate API failed</span>';
                  } else {
                    this.local_amount = (usdAmount * this.ratio).toFixed(2);
                  }
                  console.log("quantity", quantity);
                  console.log("usdAmount", usdAmount);
                  console.log("ratio", this.ratio);
                  console.log("local_amount = " + this.local_amount);
                   var usdAmtdec =  (usdAmount).toFixed(2);
                  var amt = this.local_amount;
                  console.log("amt = " + amt);
                  var amtLen = amt.substr(0, amt.indexOf("."));
                  var lenAmt = amtLen.length;
                  // console.log("lenAmt = " + lenAmt);
                  if (lenAmt == 0) {
                    lenAmt = amt.length;
                  }
                  amt = amt.replace(".", "");
                  amt = PadAfter(amt, 5);
                  console.log('dob1 = ' + dob1);
                  console.log('amt = ' + amt);
                  var chkVal = mrvRef + dob1 + amt;
                  console.log("chkVal = " + chkVal);
                  var chkDig = checkDigit(chkVal);
                  chkDig = "DOSAC%09" + mrvRef + amt + dob1;
                  console.log("chkDig = " + chkDig);
                  // Avnish, 19-May-23, Panama country remove first 4 digit reference no..
                  if(countryName == "Panama") {
                    console.log("referenceNumber:",referenceNumber)
                    // Avnish, 24-May-23, Panama country show 10 digit number 0nly
                    const removeDigitLength = referenceNumber.length - 10;
                    if(removeDigitLength > 0) {
                      referenceNumber = referenceNumber.slice(removeDigitLength);
                    }
                    console.log("after referenceNumber:",referenceNumber)
                  }
                  // Avnish, 07-June-23, Malaysia country deposite slip change only, not for deposite1
                  if(countryName == "Malaysia"){
                    if(urlSlugText == 'depositslip') {
                      referenceNumber = referenceNumber.substr(0,12);
                      referenceNumber = referenceNumber + "" + dt + mth + year.substr(2, 4);
                    }
                    if(urlSlugText == 'depositslip2') {
                      let mlTierCodePrefix = visaDetails[0]['mltier-code'];
                      if(quantity > 1) {
                        mlTierCodePrefix = "80004";
                      }
                      if(mlTierCodePrefix) {
                        if(uid) {
                          let tempReferenceNo = mlTierCodePrefix + ""+ uid;
                          if(tempReferenceNo.length < 14) {
                            for (let i = 0; i < (14-tempReferenceNo.length); i++) {
                              uid = uid+"0";
                            }
                          }
                          referenceNumber = mlTierCodePrefix + ""+ uid;
                        }
                        else {
                          referenceNumber = mlTierCodePrefix + ""+ referenceNumber;
                          referenceNumber = referenceNumber.substr(0,14);
                        }
                      }
                      UniqueBeneficiaryAccNo = referenceNumber;
                    }
                  }
                  console.log("referenceNumber:",referenceNumber, countryName)

                  var re = new RegExp("{RefNo}", 'g');
                  var refNo;
                  //pooja, 23-nov-22, new key for virtualAccount and barcode url from content
                  this.referenceNo = referenceNumber;
                  console.log("this.countryName::",this.countryName, urlSlugText, tierCode);
                  if(this.countryName == 'Singapore'){
                    virtualaccountSuffix = referenceNumber.substr(2,13);
                    if(urlSlugText == 'depositslip'){
                      if (tierCode == 73) {
                        virtualaccount = referenceNumber+'0';
                      } else if (tierCode == 85) {
                        virtualaccount = referenceNumber+'0';
                      } else if (tierCode == 62) {
                        virtualaccount = referenceNumber+'0';
                      } else if (tierCode == 40) {
                        virtualaccount = referenceNumber+'0';
                      }
                    }
                    //Avnish, 13-June-23, depositeslip1 and depositeslip2 condition changes for singapore.
                    if(urlSlugText == 'depositslip1' || urlSlugText == 'depositslip2'){
                      if (tierCode == 73) {
                        virtualaccount = '885149'+referenceNumber;
                      } else if (tierCode == 85) {
                        virtualaccount = '885149'+referenceNumber;
                      } else if (tierCode == 62) {
                        virtualaccount = '885149'+referenceNumber;
                      } else if (tierCode == 40) {
                        virtualaccount = '885149'+referenceNumber;
                      }
                      referenceNumber = virtualaccount;
                    }
                  }
                  if(this.countryName == 'Taiwan'){
                    var virtualaccountSuffix = referenceNumber.substr(2,14);
                    if (tierCode == 73) {
                      virtualaccount = '1361'+virtualaccountSuffix;
                    } else if (tierCode == 85) {
                      virtualaccount = '1362'+virtualaccountSuffix;
                    } else if (tierCode == 62) {
                      virtualaccount = '1363'+virtualaccountSuffix;
                    } else if (tierCode == 40) {
                      virtualaccount = '1364'+virtualaccountSuffix;
                    }
                  }

                  //pooja, 23-nov-22, recceipt no random generation for Philippines
                  if(countryName == 'Philippines'){
                    refNo = this.receiptNumberGen
                    this.htmlData = this.htmlData.replace(re, refNo);
                  }else{
                    this.htmlData = this.htmlData.replace(re, referenceNumber);
                  }
                    getBarcodeUrl(this.$route.params.countrycode).then(
                      (barcodeUrl) =>{
                        console.log("barcodeUrl = " + barcodeUrl);
                        // Avnish 09-May-2023 Australia country bar code number formate condition put
                        getWeights(this.$route.params.countrycode).then(
                        (weights) =>{
                          console.log("weights = ", weights)
                          if(!barcodeUrl.includes('not found')){
                            var refWithAmt;
                            var prefix='';
                            var key_lines = "";
                            if(this.countryName == 'Romania'){
                              virtualaccountSuffix = referenceNumber.substr(0,8);
                              if (amt <10) {
                              prefix = '0000';
                              } else if (amt <1000) {
                              prefix = '000';
                              } else if (amt <10000) {
                                prefix = '00';
                              } else if (amt <100000) {
                                prefix = '0';
                              } else if (amt>100000 && amt <1000000) {
                                virtualaccountSuffix = virtualaccountSuffix.substr(1);
                              }
                              var localamount2 = ('' + amt ).replace(".", "");
                              refWithAmt = referenceNumber + expiration_date2 + prefix + localamount2;
                              console.log("romania refWithAmt:",refWithAmt);
                            }
                            else if(this.countryName == 'Hong Kong') {
                              // Avnish 01-June-2023 Hong-Kong country bar code number formate condition put
                              let hk_amount = this.local_amount;
                              let slip_codes = "438"+"01"+referenceNumber+"45"+"0000"+amt;
                              refWithAmt = slip_codes;
                              console.log("refWithAmt::",refWithAmt);
                              chkDig = refWithAmt;
                            }
                            else if (this.countryName == 'Cambodia'){
                              refWithAmt = referenceNumber + "&style=197&type=C128B&width=200&height=60&xres=1&font=3" ;
                            }
                           
                           /// Jordan slip changes
                            else if (this.countryName == 'Jordan'){
                                var receipt_number = this.visaType+receipt_number;
                                 let amt = parseFloat(this.local_amount).toFixed(3);
                                 var prefix = "";
                                if (amt < 1000)
                                    prefix = '00';
                                else if (amt < 10000)
                                    prefix = '0';
                                 var bar_code_number = referenceNumber + prefix + amt;
                                refWithAmt = bar_code_number;

                                chkDig = refWithAmt;
                                
                                 var re = new RegExp("{Barcode3digits}", 'g');
                                 this.htmlData = this.htmlData.replace(re, refWithAmt); 
                

                              }
                           
                           
                           
                            else if(this.countryName == 'Taiwan'){
                              
                              var temp = this.htmlData.split("{Barcode1}");
                              console.log('temppp',temp.length)
                              var htmlData='';
                              for(var i = 0; i < temp.length; i++){
                                console.log('i refWithAmt',i +'=='+ 4)

                                if(i == 3){
                                  if (amt <1000) {
                                    prefix = '00';
                                  } else  if (amt <10000) {
                                    prefix = '0';
                                  }
                                  refWithAmt = referenceNumber +prefix+ (amt);                            
                                }
                                else if(i == 1){
                                  refWithAmt = virtualaccount;                            
                                }
                                else if(i == 2){
                                  var localamount2 = ('' + this.local_amount ).split(".")[0];
                                  refWithAmt = localamount2;                            
                                }else if(i == 0){
                                  var localamount = 50239813;
                                  refWithAmt = localamount;                            
                                }
                                this.url = barcodeUrl + refWithAmt;
                                if(i < 3 ) htmlData = htmlData+temp[i]+`<img src="${this.url}" ><br><div id="barcode"></div>`;
                                else htmlData = htmlData+temp[i]
                              }
                              this.htmlData = htmlData;
                            }
                            else if ((this.countryName == 'Costa Rica')){
                              refWithAmt = '1111' + amt + referenceNumber;
                              console.log("Ref:"+refWithAmt);
                            }
                            else if ((this.countryName == 'Nicaragua')){
                              refWithAmt = '111100' + amt + referenceNumber;
                              console.log("Ref:"+refWithAmt);
                            }
                            else if ((this.countryName == 'El Salvador')){
                              refWithAmt = '1111000' + amt + referenceNumber;
                              console.log("Ref:"+refWithAmt);
                            }
                            else if(this.countryName == 'Haiti') {
                               refWithAmt = referenceNumber +prefix+ this.local_amount;
                            }
                            else if(this.countryName == 'Australia') {
                              // Avnish 09-May-2023 Australia country bar code number formate condition put
                              var mrv_numbers = mrvRef.split("");
                              var difference_in_mrv_ref = mrv_numbers.length - 17;

                              if(difference_in_mrv_ref > 0) {
                                mrv_numbers = mrv_numbers.slice(0, 16);
                              }
                              if(difference_in_mrv_ref < 0) {
                                for (let index = 0; index < (-1 * difference_in_mrv_ref); index++) {
                                  mrv_numbers = ["0", ...mrv_numbers];
                                }
                              }
                              console.log("mrv_numbers:::",mrv_numbers, difference_in_mrv_ref);
                              if(typeof weights != "string") {
                                var mrv_range_weights = weights;
                                var expiry_date_arr = expiration_date2.split("");
                                for (let i = 0; i < expiry_date_arr.length; i++) {
                                  const element = expiry_date_arr[i];
                                  mrv_numbers.push(element);
                                }
                                var stramt = amt.toString();
                                var amount_arr = stramt.split("");
                                for (let i = 0; i < amount_arr.length; i++) {
                                  const element = amount_arr[i];
                                  mrv_numbers.push(element);
                                }
                                var product_sum = 0;
                                var bar_code = "*4247";
                                key_lines = "*4247 ";
                                for (let index = 0; index < 30; index++) {
                                  var num = mrv_numbers[index];
                                  var key_num = index + 1;
                                  if(key_num < 10) {
                                    key_num = "0"+key_num;
                                  }
                                  if(mrv_range_weights[key_num] != null && mrv_range_weights[key_num] != undefined) {
                                    var weight = mrv_range_weights[key_num];
                                    // console.log("num === = ", num);
                                    // console.log("weight === = ", weight);
                                    var product = num * weight;
                                    // console.log("product === = ", product);
                                    product_sum = product_sum + product;
                                    
                                    if(index == 25) {
                                      bar_code = bar_code+""+num;
                                      // key_lines = key_lines+" $"+num;
                                    }
                                    else {
                                      bar_code = bar_code+""+num;
                                      if(index == 17) {
                                        key_lines = key_lines+" "+num;
                                      }
                                      else {
                                        if(index < 25) {
                                          key_lines = key_lines+""+num;
                                        }
                                      }
                                    }
                                  }
                                }
                                var static_value = 97;
                                var final_value = product_sum/static_value;
                                final_value = final_value.toFixed(2);
                                var full_amount = parseInt(final_value) * static_value;
                                var difference = product_sum - full_amount;
                                var cd_digit = static_value - difference;
                                if(cd_digit < 10) {
                                  cd_digit = "0"+cd_digit;
                                }
                                bar_code = bar_code + cd_digit;
                                key_lines = key_lines +" $"+this.local_amount+ " " + cd_digit;
                                console.log("product_sum, final_value, full_amount, cd_digit", product_sum, final_value, full_amount, cd_digit);
                                // weightstr = weightstr + cd_digit;
                                // productstr = productstr + cd_digit;
                                console.log("bar_code:",bar_code);
                                console.log("key_lines:",key_lines);
                                refWithAmt = bar_code;
                              }
                            }
                            else{
                              if (amt <1000) {
                                prefix = '00';
                              } else  if (amt <10000) {
                                prefix = '0';
                              }
                              refWithAmt = referenceNumber +prefix+ (amt);
                              console.log('refWithAmt',refWithAmt)
                            }
                            this.url = barcodeUrl + refWithAmt;
                            var re = new RegExp("{Barcode1}", "g");
                            if (isNaN(this.ratio) == true) {               
                              this.htmlData = this.htmlData.replace(re,`<span style="color: red;">To generate barcode exchange Rate API Failed</span>`);
                            }else{                
                              if(this.countryName == 'Australia') {
                                this.htmlData = this.htmlData.replace(
                                  re,
                                  `<img src="${this.url}" ><br><div id="barcode"></div>${key_lines}`
                                );
                              }
                              else {
                                this.htmlData = this.htmlData.replace(
                                  re,
                                  `<img src="${this.url}" ><br><div id="barcode"></div>`
                                );
                              }
                            } 
                          }else{
                            if(this.countryName == 'Bulgaria'){
                              chkDig = referenceNumber
                            }
                            if(this.countryName == 'Singapore'){
                              if(urlSlugText == 'depositslip1') chkDig = virtualaccount;                        
                            }
                            if(this.countryName == 'Hong Kong') {
                              // Avnish 01-June-2023 Hong-Kong country bar code number formate condition put
                              let hk_amount = this.local_amount;
                              let slip_codes = "438"+"01"+referenceNumber+"45"+"0000"+amt;
                              refWithAmt = slip_codes;
                              console.log("refWithAmt::",refWithAmt);
                              chkDig = refWithAmt;
                            }
                            this.url = "https://cgi-barcodes.herokuapp.com/barcode/code128/a/" + chkDig;
                            var re = new RegExp("{Barcode1}", "g");
                            if (isNaN(this.ratio) == true) {               
                              this.htmlData = this.htmlData.replace(re,`<span style="color: red;">To generate barcode exchange Rate API Failed</span>`);
                            }else{                
                              this.htmlData = this.htmlData.replace(
                                re,
                                `<img src="${this.url}" ><br><div id="barcode"></div>`
                              );
                            } 
                          }
                        })
                      }
                    )  
                    var virtualaccount;
                    var virtualaccountSuffix;
                    if(this.countryName == 'Thailand'){
                      virtualaccountSuffix = referenceNumber.substr(4,6);
                      if (tierCode == 73) {
                        virtualaccount = '83300'+virtualaccountSuffix;
                      } else if (tierCode == 85) {
                        virtualaccount = '83301'+virtualaccountSuffix;
                      } else if (tierCode == 62) {
                        virtualaccount= '83302'+virtualaccountSuffix;
                      } else if (tierCode == 40) {
                        virtualaccount = '83303'+virtualaccountSuffix;
                      }
                    }
                    if(this.countryName == 'Indonesia'){
                      virtualaccountSuffix = referenceNumber.substr(2,13);
                      if (tierCode == 73) {
                        virtualaccount = '97090'+virtualaccountSuffix+'0';
                      } else if (tierCode == 85) {
                        virtualaccount = '97091'+virtualaccountSuffix+'0';
                      } else if (tierCode == 62) {
                        virtualaccount = '97092'+virtualaccountSuffix+'0';
                      } else if (tierCode == 40) {
                        virtualaccount = '97093'+virtualaccountSuffix+'0';
                      }
                    }

                    // ZS, 2 Aug - Vietnam depositslip 
                    if(this.countryName == 'Vietnam') {
                      amt = this.local_amount.split(".")[0];
                    }

                    if(amount != undefined){
                      this.local_amount = this.ratio * amount;
                      if (isNaN(this.ratio)) {
                        this.local_amount = `<span style="color: red;">Local amount not found exchange rate API failed</span>`;
                      }
                    }
                    //Pooja, 04-aug-23, removed decimals from amount
                    if(this.countryName == 'Taiwan'){
                      this.local_amount = this.local_amount.toString().split(".")[0];
                    }
                    // console.log('virtualaccountcountry',this.countryName)
                    // console.log('virtualaccountSuffix',virtualaccountSuffix)
                    // console.log('virtualaccounttierCode',tierCode)
                    // console.log('virtualaccount',virtualaccount);
                    // console.log("Tiercode:" + tierCode)
                    // console.log("Quantity:" + quantity)
                    //pooja, 03-mar-23, no need now we are taking from lang global resource group 
                    // var fees;
                    // if (tierCode === "73") {
                    //   tier = 1;
                    //   fees = 160;
                    // } else if (tierCode === "85") {
                    //   tier = 2;
                    //   fees = 190;
                    // } else if (tierCode === "62") {
                    //   tier = 3;
                    //   fees = 265;
                    // } else if (tierCode === "40") {
                    //   tier = 4;
                    //   fees = 205;
                    // }
                    // if (!fee) {
                    //   fee = fees;
                    // }
                    // tierCode = tier;
                    // console.log("fee " + fee)
                    // console.log("tierCode " + tierCode)

                    if (!quantity) {
                      quantity = referenceNumber.substr(2, 4);
                    }
                    //pooja 13-sep-22 date replacement and replace global
                    var d = new Date();
                    var month = (d.getMonth() + 1).toString().padStart(2, "0");
                    var day = d.getDate().toString().padStart(2, "0");
                    var year = d.getFullYear();
                    this.formatDate = year+'-'+month+'-'+day;
                    //pooja, 23-nov-22, replacement for current time
                    var hours = (d.getHours()<10?'0':'') + d.getHours()//Get the hour (0-23)
                    var min = (d.getMinutes()<10?'0':'') + d.getMinutes()  //Get the minute (0-59)
                    var formatTime = hours+':'+min
                    var re = new RegExp("{DepositAmtLocal}", 'g');
                    this.htmlData = this.htmlData.replace(re, this.local_amount); 
                    var re = new RegExp("{ExpDate}", 'g');
                    this.htmlData = this.htmlData.replace(re, this.expiryDate);
                    // var re = new RegExp("{VisaType}", 'g');
                    // this.htmlData = this.htmlData.replace(re, tierCode);
                    var re = new RegExp("{VisaTier}", "g");
                    this.htmlData = this.htmlData.replace(re, tier);
                    var re = new RegExp("{TierCode}", "g");
                    this.htmlData = this.htmlData.replace(re, tierCode);
                    var re = new RegExp("{Qty}", "g");
                    this.htmlData = this.htmlData.replace(re, quantity);
                    var re = new RegExp("{Fee}", "g");
                    this.htmlData = this.htmlData.replace(re, fee);
                    var re = new RegExp("{Today}", "g");
                    this.htmlData = this.htmlData.replace(re, this.formatDate);                 
                    //pooja, 23-nov-22, new key for time and virtualAccount
                    var re = new RegExp("{TimeNow}", "g");
                    this.htmlData = this.htmlData.replace(re, formatTime);
                    var re = new RegExp("{VirtualAccount}", "g");
                    this.htmlData = this.htmlData.replace(re, virtualaccount);
                    if(visaClass !=undefined){
                      var re = new RegExp("{VisaClass}", "g");
                      this.htmlData = this.htmlData.replace(re, visaClass);
                    }
                    var re = new RegExp("{DepositAmtUSD}", 'g');
                    this.htmlData = this.htmlData.replace(re,  usdAmount);   
                  // console.log('this.htmlData',this.htmlData)
                       var re = new RegExp("{DecimalDepositAmtUSD}", 'g');
                     // var usdAmtNoDecimal = parseFloat.usdAmount.toFixed(2);
                      this.htmlData = this.htmlData.replace(re,usdAmtdec); 
                      
                    /// 3 digit amt decimals  
                    var re = new RegExp("{threedigitsAmt}", 'g');
                    var amt_three = parseFloat(this.local_amount).toFixed(3);
                    this.htmlData = this.htmlData.replace(re, amt_three); 
                    

                  // Avnish, 07-June-2023, Korea changes.
                  var re = new RegExp("{UniqueBeneficiaryAccNo}", "g");
                  this.htmlData = this.htmlData.replace(re, UniqueBeneficiaryAccNo); 

                  // Avnish, 07-June-2023, vietnam changes.
                  var re = new RegExp("{barcodeNo}", "g");
                  this.htmlData = this.htmlData.replace(re, referenceNumber); 

                  // Avnish, 07-June-2023, vietnam changes.
                  var re = new RegExp("{barcodeAmount}", "g");
                  this.htmlData = this.htmlData.replace(re, amt); 
                  //pooja, 14-02-24, number of applicants for pakistan
                  if(this.countryName == 'Pakistan'){
                    var re = new RegExp("{NumberOfApplicants}", "g");
                    this.htmlData = this.htmlData.replace(re, quantity); 
                  }
              });
            });
          });
        });
      });      
    });            
      var UniqueBeneficiaryAccNo;
      var randomnumber=new Array();
      if(urlSlugText == 'kr-niv-eft160'){
        UniqueBeneficiaryAccNo = '2400';     
      }
      if(urlSlugText == 'kr-niv-eft-190'){
        UniqueBeneficiaryAccNo = '2500';        
      }
      if(urlSlugText == 'kr-niv-eft-265'){
        UniqueBeneficiaryAccNo = '4500';        
      }
      if(urlSlugText == 'kr-niv-eft-205'){
        UniqueBeneficiaryAccNo = '4900';        
      }
      // Avnish, 07-June-2023, Korea changes.
      if(UniqueBeneficiaryAccNo) {
        for(var i = 0; i < 10; i++) {
          randomnumber[i]=Math.floor(Math.random()*10);
          UniqueBeneficiaryAccNo = UniqueBeneficiaryAccNo + randomnumber[i];
        }      
        var re = new RegExp("{UniqueBeneficiaryAccNo}", "g");
        this.htmlData = this.htmlData.replace(re, UniqueBeneficiaryAccNo); 
      }
      var today = new Date();
      function Pad(number, length) {
        var str = "" + number;
        while (str.length < length) {
          str = "0" + str;
        }
        return str;
      }
      function PadAfter(number, length) {
        var str = "" + number;
        while (str.length < length) {
          str = str + "0";
        }
        return str;
      }

      function checkDigit(test) {
        var tmp = new Array();
        for (var i = 0; i < 30; i++) {
          tmp.push(test.substr(i, i + 1));
        }
        var temp = new Array(
          3,
          5,
          7,
          11,
          13,
          17,
          19,
          23,
          29,
          31,
          37,
          41,
          43,
          47,
          53,
          59,
          61,
          67,
          71,
          73,
          79,
          83,
          89,
          97,
          101,
          103,
          107,
          109,
          113,
          127
        );
        var product = 0;
        for (var i = 0; i < 30; i++) {
          product = product + temp[i] * tmp[i];
        }
        var result;
        result = product / 97;
        // console.log("result", result.toString())
        var floor = Math.floor(result);
        // console.log("floor", floor.toString())
        var mult = floor * 97;
        // console.log("mult", mult.toString())
        var out = product - mult;
        // console.log("out", out.toString())
        var output = 97 - out;
        // console.log("output", output.toString());
        // console.log("output", output);
        return output;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.display-none {
  display: none;
}
.page-header {
  padding: 2rem 0 3rem 0;
  background-color: #205493;

  ::deep .content {
    max-width: 40rem;
    padding: 0 2rem;
    h1 {
      font-family: Helvetica,Merriweather_Web, Georgia, Cambria, Times New Roman, Times,
        serif;
      color: white;
      font-size: 2.9rem;
    }
    p {
      font-family: Helvetica,Source_Sans_Pro_Web, Helvetica Neue, Helvetica, Roboto, Arial,
        sans-serif;
      color: white;
      line-height: 1.5;
    }
  }
}
::v-deep .usa-prose {
  h1 {
    font-family: Helvetica,"Helvetica", sans-serif;
    line-height: 1.22983;
    font-weight: 700;
    font-size: 2.43935rem;
  }
  p {
    line-height: 1.52155;
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.06471rem;
  }
  h2 {
    margin-top: 1em;
    font-size: 1.95148rem;
    line-height: 1.22983;
  }
  h3 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    line-height: 1.22983;
    font-size: 1.34164rem;
  }
  h4 {
    font-size: 1.13125rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 1.13125rem;
  }
}
::v-deep ul {
  padding: 0;
  padding-left: 25px;
  li {
    margin-bottom: 0.25em;
    font-size: 1.06471rem;
    p {
      margin: 0 !important;
    }
  }
}
::v-deep ol {
  padding: 0;
  padding-left: 25px;
  li {
    line-height: 2;
    p {
      margin: 0 !important;
    }
  }
}
.usa-layout-docs {
  border-top: 1px solid #dcdee0;
}
.block-link {
  display: flex;
  align-items: center;
}
.usa-link {
  ::v-deep p {
    margin: 0;
  }
}
.slip-grid {
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid #ccc;
  padding: 0 15px;
  border-bottom: none;
  &:last-child {
    border-bottom: 1px solid #ccc;
  }
}
</style>
